import React from 'react'

import { Icon } from 'components/@wartek'

import { UIComponentMap } from './ComponentMap'
interface ComponentBuilderProps {
  // eslint-disable-next-line
  data: any
  highlightedId?: string
  isHightlightedChild?: boolean
  isBlueprint?: boolean
  setHightlightedComponentId?: (data: any) => void
  nodeConfig?: any
  deleteSelectedNode?: (data: any) => void
  moveNodeOrder?: (data: any) => void
  selectedNodeIndex?: any
  triggerDoubleClick?: (data: any) => void
}

const DefaultComponentMap = 'div'
const SUPPORTED_CONTAINER_HIGHLIGHT = ['div', 'section', 'article', 'Card']

const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

const filterEmptyObjectFromArray = (arr) => {
  return arr.filter((obj) => {
    return !isObjectEmpty(obj)
  })
}

const getSelectedComponentByName = (componentName) => {
  const componentByName = componentName || DefaultComponentMap
  const getComponentList = UIComponentMap

  if (getComponentList[componentByName]) {
    return getComponentList[componentByName]
  }

  return <> </>
}

// TODO: learn more about refs
// eslint-disable-next-line
const RenderComponentBlueprint = (props: any) => {
  const {
    data,
    highlightedId = 'nein',
    isHightlightedChild = false,
    setHightlightedComponentId,
    deleteSelectedNode,
    moveNodeOrder,
    selectedNodeIndex,
    triggerDoubleClick,
    // nodeRef,
  } = props
  const componentDataList = data
  if (componentDataList.length === 0) {
    return <> </>
  }

  return componentDataList.map((componentNodeData) => {
    const SelectedComponent = getSelectedComponentByName(
      componentNodeData.componentName
    )
    const props = componentNodeData?.props || {}
    const childrenNode = componentNodeData?.children || []
    const childNodes = filterEmptyObjectFromArray(childrenNode)
    const componentClassName = componentNodeData?.props?.className || ''
    const componentId = componentNodeData?.componentId || ''
    const isComponentHasChildren = childNodes.length > 0

    // const latestNodeIndex = Number(
    //   (selectedNodeIndex && selectedNodeIndex[selectedNodeIndex.length - 1]) ||
    //     0
    // )
    const isDisableMoveNodeUp = true // disabled for now because super buggy
    const isDisableMoveNodeDown = true // disable this for now because buggy
    const isHighlighted = componentId === highlightedId || isHightlightedChild

    if (isHighlighted) {
      const highlightedEl = document.querySelector(
        `[data-renderedId="${componentId}"]`
      )

      highlightedEl?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }

    if (highlightedId && isHighlighted) {
      if (
        SUPPORTED_CONTAINER_HIGHLIGHT.includes(componentNodeData.componentName)
      ) {
        return (
          // @ts-ignore
          <SelectedComponent
            key={`${componentNodeData.componentName}-${componentNodeData.componentId}`}
            {...props}
            data-renderedId={componentId}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setHightlightedComponentId(componentNodeData)
              triggerDoubleClick(false)
            }}
            onDoubleClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setHightlightedComponentId(componentNodeData)
              triggerDoubleClick(true)
            }}
            className={`${componentClassName} border-box z-10 m-[-4px] border-4 border-dashed border-pink-400`}
          >
            <div className="absolute mt-[-35px] flex p-1">
              <div className="flex flex-row items-center">
                <div
                  className=" mr-2 flex rounded bg-[#f00] p-1 text-center"
                  onClick={() => deleteSelectedNode()}
                >
                  <Icon color="inverse" fontSize="small">
                    delete
                  </Icon>
                </div>
                {componentNodeData?.templateName && (
                  <div className="mr-2 rounded bg-[#77a6ff] p-1">
                    <span
                      className="text-xs text-white"
                      style={{ fontWeight: 'bold', fontSize: '16px' }}
                    >
                      {componentNodeData.templateName}
                    </span>
                  </div>
                )}
                <div className="mr-2 rounded bg-pink-400 p-1">
                  <span
                    className="text-xs text-white"
                    style={{ fontWeight: 'bold', fontSize: '16px' }}
                  >
                    {componentNodeData.componentName}
                  </span>
                </div>
              </div>
              {!isDisableMoveNodeDown && (
                <div
                  className={` mr-2 flex rounded bg-[#390cff] p-1 text-center`}
                  onClick={() => moveNodeOrder('down', isComponentHasChildren)}
                >
                  <Icon color="inverse" fontSize="small">
                    expand_more
                  </Icon>
                </div>
              )}

              {!isDisableMoveNodeUp && (
                <div
                  className={` mr-2 flex rounded bg-[#390cff]
                   p-1 text-center`}
                  onClick={() => moveNodeOrder('up', isComponentHasChildren)}
                >
                  <Icon color="inverse" fontSize="small">
                    expand_less
                  </Icon>
                </div>
              )}
            </div>
            {childNodes &&
              childNodes.map((child) => {
                return RenderComponentBlueprint({
                  data: [child],
                  highlightedId: highlightedId,
                  isHightlightedChild: child.componentId === highlightedId,
                  setHightlightedComponentId,
                  deleteSelectedNode,
                  moveNodeOrder,
                  selectedNodeIndex,
                  triggerDoubleClick,
                })
              })}
          </SelectedComponent>
        )
      }
      return (
        <div
          key={`${componentNodeData.componentName}-${componentNodeData.componentId}`}
          className={`border-box z-10 m-[-4px] border-4 border-dashed border-pink-400`}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setHightlightedComponentId(componentNodeData)
            triggerDoubleClick(false)
          }}
          onDoubleClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setHightlightedComponentId(componentNodeData)
            triggerDoubleClick(true)
          }}
        >
          <div className="absolute mt-[-35px] flex p-1">
            <div className="flex flex-row items-center">
              <div
                className=" mr-2 flex rounded bg-[#f00] p-1 text-center"
                onClick={() => deleteSelectedNode()}
              >
                <Icon color="inverse" fontSize="small">
                  delete
                </Icon>
              </div>
              {componentNodeData?.templateName && (
                <div className="mr-2 rounded bg-[#77a6ff] p-1">
                  <span
                    className="text-xs text-white"
                    style={{ fontWeight: 'bold', fontSize: '16px' }}
                  >
                    {componentNodeData.templateName}
                  </span>
                </div>
              )}
              <div className="mr-2 rounded bg-pink-400 p-1">
                <span
                  className="text-xs text-white"
                  style={{ fontWeight: 'bold', fontSize: '16px' }}
                >
                  {componentNodeData.componentName}
                </span>
              </div>
              {/* TODO: rework this, supper buggy right now */}

              {!isDisableMoveNodeDown && (
                <div
                  className={` mr-2 flex rounded bg-[#390cff] p-1 text-center`}
                  onClick={() => moveNodeOrder('down', isComponentHasChildren)}
                >
                  <Icon color="inverse" fontSize="small">
                    expand_more
                  </Icon>
                </div>
              )}

              {!isDisableMoveNodeUp && (
                <div
                  className={` mr-2 flex rounded bg-[#390cff]
                   p-1 text-center`}
                  onClick={() => moveNodeOrder('up', isComponentHasChildren)}
                >
                  <Icon color="inverse" fontSize="small">
                    expand_less
                  </Icon>
                </div>
              )}
            </div>
          </div>

          {/* @ts-ignore */}
          <SelectedComponent
            key={`${componentNodeData.componentName}-${componentNodeData.componentId}`}
            {...props}
            data-renderedId={componentId}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setHightlightedComponentId(componentNodeData)
              triggerDoubleClick(false)
            }}
            onDoubleClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setHightlightedComponentId(componentNodeData)
              triggerDoubleClick(true)
            }}
          >
            {childNodes &&
              childNodes.map((child) => {
                return RenderComponentBlueprint({
                  data: [child],
                  highlightedId: highlightedId,
                  isHightlightedChild: child.componentId === highlightedId,
                  setHightlightedComponentId,
                  deleteSelectedNode,
                  moveNodeOrder,
                  selectedNodeIndex,
                  triggerDoubleClick,
                })
              })}

            {!isDisableMoveNodeDown && (
              <div
                className={` mr-2 flex rounded bg-[#390cff] p-1 text-center`}
                onClick={() => moveNodeOrder('down', isComponentHasChildren)}
              >
                <Icon color="inverse" fontSize="small">
                  expand_more
                </Icon>
              </div>
            )}

            {!isDisableMoveNodeUp && (
              <div
                className={` mr-2 flex rounded bg-[#390cff]
                   p-1 text-center`}
                onClick={() => moveNodeOrder('up', isComponentHasChildren)}
              >
                <Icon color="inverse" fontSize="small">
                  expand_less
                </Icon>
              </div>
            )}
          </SelectedComponent>
        </div>
      )
    }

    return (
      // @ts-ignore
      <SelectedComponent
        key={`${componentNodeData.componentName}-${componentNodeData.componentId}`}
        className={componentClassName}
        {...props}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setHightlightedComponentId(componentNodeData)
          triggerDoubleClick(false)
        }}
        onDoubleClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setHightlightedComponentId(componentNodeData)
          triggerDoubleClick(true)
        }}
      >
        {childNodes &&
          childNodes.map((child) => {
            return RenderComponentBlueprint({
              data: [child],
              highlightedId: highlightedId,
              isHightlightedChild: child.componentId === highlightedId,
              setHightlightedComponentId,
              deleteSelectedNode,
              moveNodeOrder,
              selectedNodeIndex,
              triggerDoubleClick,
            })
          })}
      </SelectedComponent>
    )
  })
}

const MemoizedRenderComponentBlueprint = React.memo(RenderComponentBlueprint)

// eslint-disable-next-line
const RenderComponent = (props: any) => {
  const {
    data,
    highlightedId = '',
    isHightlightedChild = false,
    nodeConfig = null,
  } = props
  const componentDataList = data
  if (componentDataList.length === 0) {
    return <> </>
  }

  return componentDataList.map((componentNodeData) => {
    const SelectedComponent = getSelectedComponentByName(
      componentNodeData.componentName
    )
    const props = componentNodeData?.props || {}

    const childrenNode = componentNodeData?.children || []
    const childNodes = filterEmptyObjectFromArray(childrenNode)
    const componentClassName = componentNodeData?.props?.className || ''

    const componentId = componentNodeData?.componentId || ''
    const isHighlighted = componentId === highlightedId || isHightlightedChild
    let overrideComponentData = { props, children: childNodes }

    const eventListenerId = componentNodeData?.eventListenerId || ''
    if (eventListenerId && nodeConfig) {
      overrideComponentData = {
        props: {
          ...props,
          ...nodeConfig[eventListenerId].props,
        },
        children: [
          ...childNodes,
          ...(nodeConfig[eventListenerId].children || []),
        ],
      }
    }

    if (highlightedId) {
      return (
        <div
          key={`${componentNodeData.componentName}-${componentNodeData.componentId}`}
          className={`
            ${
              isHighlighted
                ? 'my-1 rounded-sm border-2 border-dashed border-pink-400 p-1'
                : ''
            }`}
          style={{
            zIndex: 2,
          }}
        >
          {/* @ts-ignore */}
          <SelectedComponent
            key={`${componentNodeData.componentName}-${componentNodeData.componentId}`}
            className={componentClassName}
            {...overrideComponentData.props}
          >
            {overrideComponentData.children &&
              overrideComponentData.children.map((child) => {
                return RenderComponent({
                  data: [child],
                  highlightedId: highlightedId,
                  isHightlightedChild: child.componentId === highlightedId,
                  nodeConfig: nodeConfig,
                })
              })}
          </SelectedComponent>
        </div>
      )
    }

    return (
      // @ts-ignore
      <SelectedComponent
        key={`${componentNodeData.componentName}-${componentNodeData.componentId}`}
        className={componentClassName}
        {...overrideComponentData.props}
      >
        {overrideComponentData.children &&
          overrideComponentData.children.map((child) => {
            return RenderComponent({
              data: [child],
              highlightedId: highlightedId,
              isHightlightedChild: child.componentId === highlightedId,
              nodeConfig: nodeConfig,
            })
          })}
      </SelectedComponent>
    )
  })
}

export const ComponentBuilder = (props: ComponentBuilderProps) => {
  const {
    data,
    highlightedId = '',
    isHightlightedChild = false,
    isBlueprint,
    setHightlightedComponentId,
    nodeConfig = null,
    deleteSelectedNode,
    moveNodeOrder,
    selectedNodeIndex,
    triggerDoubleClick,
  } = props

  if (isBlueprint) {
    return (
      <MemoizedRenderComponentBlueprint
        data={data}
        highlightedId={highlightedId}
        isHightlightedChild={isHightlightedChild}
        setHightlightedComponentId={setHightlightedComponentId}
        deleteSelectedNode={deleteSelectedNode}
        moveNodeOrder={moveNodeOrder}
        selectedNodeIndex={selectedNodeIndex}
        triggerDoubleClick={triggerDoubleClick}
      />
    )
  }

  return (
    <RenderComponent
      data={data}
      highlightedId={highlightedId}
      isHightlightedChild={isHightlightedChild}
      nodeConfig={nodeConfig}
    />
  )
}

export default ComponentBuilder
